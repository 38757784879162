








































































































































































































































































































































































































































































































import { FormBlock, Scrollbar } from '@/app/components';
import { useAxios, useFeatureFlags } from '@/app/composable';
import store from '@/app/store';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { GeneralPolicy } from '@/modules/access-policy/models';
import { ExceptionPolicyFields, StatusCode } from '@/modules/asset/constants';
import { RunnerAPI } from '@/modules/data-checkin/api';
import { WorkflowAPI } from '@/modules/workflow-designer/api';
import { ExclamationCircleIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { AccessPolicy } from '../../access-policy/components';
import { AssetsAPI } from '../api';
import ModelFeature from '../components/ModelFeature.vue';
import { useModelRegistration } from '../composable/model-registration';
import { ModelSourceOptions } from '../constants/model-source-options.constants';

export default defineComponent({
    name: 'EditModelAsset',
    metaInfo() {
        return {
            title: (this as any).pageTitle ? `${(this as any).pageTitle} ${(this as any).modelDisplayName}` : '',
        };
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        backTo: {
            type: String,
            default: 'assets',
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        OrbitSpinner,
        FormBlock,
        AccessPolicy,
        ModelFeature,
        XIcon,
        ExclamationCircleIcon,
        Scrollbar,
    },
    setup(props, { root }) {
        const id = computed<string>(() => props.id.toString());
        const runner: Ref<{ id: string; name: string } | undefined> = ref(undefined);
        const modelDisplayName = ref<string>('');
        const previousAccessLevel = ref<string | null>(null);
        const previousAccessPolicies = ref<any>([]);
        const previousLicenseMetadata = ref<any>(null);
        const creatorId = ref<number | null>(null);
        const { isEnabled: isFeatureEnabled } = useFeatureFlags();

        const assetStatus = ref<string | null>(null);
        const readOnly = computed(
            () => assetStatus.value === StatusCode.Deleted || assetStatus.value === StatusCode.Archived,
        );

        const metadata = {
            general: true,
            distribution: true,
            extent: true,
            licensing: true,
            model: true,
            pricing: true,
        };
        const { exec, loading, error } = useAxios(true);

        const asset = ref<any>();
        const workflow = ref<any>();

        const pageTitle = computed(() => {
            if (assetStatus.value) {
                switch (assetStatus.value) {
                    case StatusCode.Deleted:
                    case StatusCode.Archived:
                        return 'View Model Details:';
                    case StatusCode.Incomplete:
                        return 'Publish Model:';
                    default:
                        return 'Edit Model Asset:';
                }
            }

            return null;
        });

        const {
            accessLevel,
            copyrightOwner,
            customLicense,
            getLicensingSchema,
            getGeneralSchema,
            initAsset,
            accessLevelOptions,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            checkLicense,
            getPricingSchema,
        } = useModelRegistration(asset, readOnly);

        asset.value = initAsset(metadata);

        const validForms = ref<any>({
            model: false,
            general: false,
            generalMetadata: false,
            accessLevelCopyrightOwner: false,
            licensing: false,
            pricing: false,
        });

        const user = computed(() => store.state.auth.user);

        const accessPolicies = ref<any>({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });
        const saveClicked = ref<boolean>(false);
        const contentRef = ref<HTMLElement>();
        const featuresRef = ref<string[]>([]);
        const encodedFeaturesRef = ref<string[]>([]);

        const cancel = () => {
            if (props.backTo === 'assets:view') {
                root.$router.push({ name: 'assets:view', params: { id: id.value, queryParams: props.queryParams } });
            } else {
                root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
            }
        };

        const assetUpdated = ref(false);

        const saveChanges = async () => {
            const clonedAsset = R.clone(asset.value);
            // licensing metadata
            if (metadata.licensing && asset.value.metadata.license) {
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;
            }

            // pricing metadata
            if (isFeatureEnabled('sharing') && metadata.pricing && asset.value.metadata.pricing) {
                if (asset.value.metadata.pricing.costCurrency) {
                    asset.value.metadata.pricing.cost = Number(asset.value.metadata.pricing.costCurrency[0].cost);
                    asset.value.metadata.pricing.currency = asset.value.metadata.pricing.costCurrency[0].currency;
                }

                if (asset.value.metadata.pricing.calculationScheme === 'Request Dependent') {
                    asset.value.metadata.pricing.cost = null;
                }
            }

            // keep previous access level and policies before updates
            previousAccessLevel.value = R.clone(asset.value.accessLevel);
            previousAccessPolicies.value = R.clone(asset.value.policies);

            asset.value.accessLevel = accessLevel.value;
            // asset.value.policies = getAccessPoliciesJSON();

            if (encodedFeaturesRef.value && encodedFeaturesRef.value.length) {
                asset.value.metadata.model.encodedFeatures = encodedFeaturesRef.value;
            }
            if (featuresRef.value && featuresRef.value.length) {
                asset.value.metadata.model.featureOrder = featuresRef.value;
            }

            if (asset.value && asset.value.id && !assetUpdated.value) {
                const payload = R.clone(asset.value);

                assetUpdated.value = true;

                if (payload.metadata && payload.metadata.pricing && payload.metadata.pricing.costCurrency) {
                    delete payload.metadata.pricing.costCurrency;
                }
                let policyChanges: {
                    addPolicies: {
                        subjectType: string;
                        subjectId: string;
                        relation: string;
                        caveat: { name: string; context: { policies: any } };
                    }[];
                } = { addPolicies: [] };
                if (accessLevel.value === AccessLevel.SelectiveSharing) {
                    policyChanges.addPolicies.push({
                        subjectType: 'user',
                        subjectId: '*',
                        relation: 'guest',
                        caveat: {
                            name: 'attribute_check',
                            context: {
                                policies: selectiveSharingExceptions.value,
                            },
                        },
                    });
                }

                exec(AssetsAPI.updateAsset(asset.value.id, { ...payload, ...policyChanges } as any))
                    .then(() => {
                        root.$router.push({
                            name: 'assets:view',
                            params: { id: `${asset.value.id}`, queryParams: props.queryParams },
                        });
                    })
                    .catch((e) => {
                        assetUpdated.value = false;
                        error.value = e;
                        asset.value = clonedAsset;
                        // on error reset asset access level and policies
                        asset.value.accessLevel = previousAccessLevel.value as AccessLevel;
                        accessLevel.value = previousAccessLevel.value;
                        asset.value.policies = previousAccessPolicies.value;
                        if (metadata.licensing) asset.value.metadata.license = previousLicenseMetadata.value;
                        (root as any).$toastr.e('Model Asset could not be updated!', 'Error');
                    });
            }
        };

        const saveAllowed = computed(() => {
            return !(
                !validForms.value.general ||
                (metadata.general && !validForms.value.generalMetadata) ||
                !validForms.value.model ||
                (metadata.licensing && (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing)) ||
                (isFeatureEnabled('sharing') &&
                    metadata.pricing &&
                    customLicense.value &&
                    accessLevel &&
                    accessLevel.value === AccessLevel.SelectiveSharing &&
                    !validForms.value.pricing)
            );
        });

        const submitForms = () => {
            saveClicked.value = true;
            validForms.value = {
                general: false,
                generalMetadata: false,
                model: false,
                accessLevelCopyrightOwner: false,
                licensing: false,
                pricing: false,
            };
            (root as any).$formulate.submit('general');
            if (metadata.general) {
                (root as any).$formulate.submit('generalMetadata');
            }
            (root as any).$formulate.submit('model');

            if (metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (!accessLevel.value || accessLevel.value === AccessLevel.OrganisationLevel) {
                    validForms.value.licensing = true;
                } else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }

            if (
                isFeatureEnabled('sharing') &&
                metadata.pricing &&
                customLicense.value &&
                accessLevel.value &&
                accessLevel.value === AccessLevel.SelectiveSharing
            ) {
                (root as any).$formulate.submit('pricing');
            }
        };

        const formSubmitted = (name: string) => {
            if (saveClicked.value) {
                validForms.value[name] = true;
                if (saveAllowed.value) {
                    saveChanges();
                } else if (contentRef.value) {
                    contentRef.value.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        const resetLicenseAndPricing = (level: any) => {
            if (level && level === AccessLevel.SelectiveSharing && asset.value.metadata.license.license !== 'Custom') {
                asset.value.metadata.license.license = 'Custom';
                checkLicense({ id: 'Custom', label: 'Custom' });
            }
        };

        const retrieveModelAssetDetails = () => {
            if (!props.id) return;
            loading.value = true;

            exec(AssetsAPI.getAsset(parseInt(id.value, 10)))
                .then((res: any) => {
                    if (res.data.createdById === user.value.id) {
                        assetStatus.value = res.data.status;
                        asset.value.name = res.data.name;
                        asset.value.accessLevel = res.data.accessLevel;
                        modelDisplayName.value = R.clone(asset.value.name);
                        previousAccessLevel.value = res.data.accessLevel;
                        previousAccessPolicies.value = res.data.policies;
                        previousLicenseMetadata.value = res.data.metadata?.license;

                        asset.value.id = res.data.id;
                        asset.value.description = res.data.description;
                        asset.value.status = res.data.status;
                        asset.value.version = res.data.version;

                        asset.value.metadata.model = res.data.metadata.model;

                        if (res.data.metadata.license) asset.value.metadata.license = res.data.metadata.license;

                        if (
                            res.data.metadata.pricing &&
                            res.data.metadata.pricing.cost !== null &&
                            res.data.metadata.pricing.currency
                        ) {
                            asset.value.metadata.pricing = res.data.metadata.pricing;
                            const cost = res.data.metadata.pricing.cost.toString();
                            const { currency } = res.data.metadata.pricing;
                            asset.value.metadata.pricing.costCurrency = [{ cost, currency }];
                        }

                        asset.value.metadata.general = res.data.metadata.general;
                        asset.value.metadata.provenance = res.data.metadata.provenance;
                        asset.value.createdById = res.data.createdById;

                        if (
                            asset.value.metadata.provenance &&
                            asset.value.metadata.provenance.type !== 'external' &&
                            !asset.value.metadata.provenance.deleted
                        ) {
                            exec(WorkflowAPI.getWorkflow(asset.value.metadata.provenance.id)).then(
                                async (workflowRes: any) => {
                                    workflow.value = workflowRes.data;
                                    creatorId.value = workflow.value.createdById;
                                },
                            );
                        }

                        if (user.value.id === asset.value?.createdById && asset.value?.metadata?.runnerId) {
                            exec(RunnerAPI.retrieve(asset.value.metadata.runnerId)).then((resRunner: any) => {
                                runner.value = resRunner.data;
                            });
                        }

                        switch (res.data.metadata?.model?.source) {
                            case ModelSourceOptions.uploaded:
                                asset.value.source = 'Upload';
                                break;
                            case ModelSourceOptions.onPremise:
                                asset.value.source = 'OnPremise';
                                break;
                            default:
                                asset.value.source = 'Platform';
                        }

                        if (res.data.metadata.model.featureOrder) {
                            featuresRef.value = res.data.metadata.model.featureOrder;
                        }
                        if (res.data.metadata.model.encodedFeatures) {
                            encodedFeaturesRef.value = res.data.metadata.model.encodedFeatures;
                        }
                        if (res.data.accessLevel) {
                            accessPolicies.value.policies = res.data.policies;
                            accessLevel.value = res.data.accessLevel;
                            copyrightOwner.value = res.data.metadata?.license?.copyrightOwner;
                        } else {
                            asset.value.metadata.license = {
                                license: null,
                                copyrightOwner: null,
                                link: null,
                                expectedUsage: [],
                                offlineRetention: [],
                            };
                            asset.value.metadata.pricing = {
                                cost: null,
                                currency: 'EUR',
                                calculationScheme: null,
                                reimbursementMethod: [],
                            };
                        }
                    }

                    loading.value = false;
                })
                .catch((e) => {
                    if (e.response.status !== 403) {
                        asset.value = null;
                        throw e; // if the error is not for forbidden access, then send to sentry
                    }
                    (root as any).$toastr.e('You do not have access to edit the specific asset.', 'Access Forbidden!');
                    root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
                });
        };

        retrieveModelAssetDetails();

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => resetLicenseAndPricing(level),
        );

        const customError = computed(() => {
            if (error.value?.response?.status === 403)
                return { title: 'Access Forbidden!', message: 'You do not have access to edit the specific asset.' };
            if (
                error.value?.response?.status === 400 &&
                R.hasPath(['response', 'data', 'validProvenance'], error.value)
            ) {
                const { validProvenance, invalidWorkflows } = error.value.response?.data;
                if (!validProvenance || invalidWorkflows?.length >= 0)
                    return {
                        title: 'Access Policy Restriction!',
                        message:
                            'Updating the access level of the specific asset is not allowed at the moment as it’s used by other pipelines in your organisation.',
                        validProvenance,
                        invalidWorkflows,
                    };
            }

            return { title: 'An error has occurred!', message: error.value?.response?.data?.message };
        });

        const storageText = computed(() => {
            if (asset.value?.metadata?.distribution?.storage) {
                if (asset.value.metadata.distribution.storage === 'cloud')
                    return 'In the Centralized Data Space of the Data Provider';
                else
                    return `In a Federated Data Space of the Data Provider ${
                        runner.value ? `(${runner.value.name})` : ''
                    }`;
            }
            return '';
        });

        const selectiveSharingExceptions = computed(() => {
            const accessPolicyExceptions: any = [];
            if (accessLevel.value === AccessLevel.SelectiveSharing) {
                accessPolicies.value.policies.forEach((policy: any) => {
                    policy.conditions.forEach((condition: any) => {
                        if (condition.fieldValues.length) {
                            const operant = condition.operant.key === 'EQUALS' ? 'in' : 'notIn';
                            const field = ExceptionPolicyFields[condition.field.key];
                            const exception: any = { [field]: { operator: operant, value: [] } };

                            condition.fieldValues.forEach((fieldValue: { id: string }) =>
                                exception[field].value.push(fieldValue.id),
                            );
                            accessPolicyExceptions.push(exception);
                        }
                    });
                });
            }
            return accessPolicyExceptions;
        });

        return {
            contentRef,
            accessLevel,
            copyrightOwner,
            customLicense,
            cancel,
            asset,
            metadata,
            getLicensingSchema,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            saveAllowed,
            saveClicked,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            featuresRef,
            loading,
            pageTitle,
            readOnly,
            StatusCode,
            assetStatus,
            encodedFeaturesRef,
            modelDisplayName,
            getGeneralSchema,
            customError,
            error,
            AccessLevel,
            previousAccessLevel,
            accessLevelOptions,
            creatorId,
            getPricingSchema,
            storageText,
            isFeatureEnabled,
        };
    },
});

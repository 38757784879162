import { FacetFilter, FacetFilterType, useAxios, useFacetsFilters } from '@/app/composable';
import store from '@/app/store';
import { computed, ref } from '@vue/composition-api';
import { ModelAPI } from '../api';
import { AssetTypeId } from '../constants';

export function useAssetFacets(get: any) {
    const { valueLabel, capitalizeAll, reducedFacetsFilters, defaultValue } = useFacetsFilters();
    const { exec } = useAxios(true);

    const conceptsByUid = ref<any>([]);

    exec(ModelAPI.conceptsUidMap()).then((res: any) => {
        conceptsByUid.value = res.data;
    });

    const countries = computed(() => store.getters.locations.countries);

    const status = {
        label: 'Status',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'status'),
        valueLabel,
    };

    const storage = {
        label: 'Storage',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'storage'),
        valueLabel: (value: string) => {
            switch (value) {
                case 'cloud':
                    return 'Centralized Data Space';
                case 'onPremise':
                    return 'Federated Data Space';
                default:
                    return '';
            }
        },
    };

    const assetFacets = computed(
        (): Record<string, FacetFilter> => {
            return {
                status,
                assetType: {
                    label: 'Asset Type',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'assetType'),
                    valueLabel: (id: number) => {
                        switch (id) {
                            case AssetTypeId.Dataset:
                                return 'Dataset';
                            case AssetTypeId.Result:
                                return 'Result';
                            case AssetTypeId.Model:
                                return 'Model';
                            default:
                                return '';
                        }
                    },
                },
                storage,
            };
        },
    );

    const timeliness = {
        label: 'Timeliness',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'timeliness'),
        valueLabel: (value: string) => capitalizeAll(value),
    };

    const datasetFacets = computed(
        (): Record<string, FacetFilter> => {
            return {
                status,
                category: {
                    label: 'Category',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'category'),
                    valueLabel: (uid: string) => {
                        if (conceptsByUid.value[uid]) return conceptsByUid.value[uid].name;
                        return uid;
                    },
                },
                accessibility: {
                    label: 'Accessibility',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'accessibility'),
                },
                accrualPeriodicity: {
                    label: 'Accrual Periodicity',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'accrualPeriodicity'),
                },
                accrualMethod: {
                    label: 'Accrual Method',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'accrualMethod'),
                },
                temporalResolution: {
                    label: 'Temporal Resolution',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'temporalResolution'),
                },
                spatialCoverage: {
                    label: 'Spatial Coverage',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'spatialCoverage'),
                    valueLabel: (value: string) => {
                        if (countries.value) {
                            const country = countries.value.find((c: any) => c.code === value);
                            if (country) return country.name;
                        }
                        return capitalizeAll(value);
                    },
                },
                timeliness,
                storage,
            };
        },
    );

    const resultFacets = computed(
        (): Record<string, FacetFilter> => {
            return { status, timeliness, storage };
        },
    );

    const modelFacets = computed(
        (): Record<string, FacetFilter> => {
            return {
                status,
                source: {
                    label: 'Source',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'source'),
                    valueLabel,
                },
                library: {
                    label: 'Library',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'library'),
                    valueLabel,
                },
                type: {
                    label: 'Type',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'type'),
                    valueLabel,
                },
                purpose: {
                    label: 'Purpose',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'purpose'),
                    valueLabel,
                },
                algorithm: {
                    label: 'Algorithm',
                    type: FacetFilterType.Checkbox,
                    default: defaultValue(get, 'algorithm'),
                    valueLabel,
                },
                storage,
            };
        },
    );

    const reducedAssetFacets: any = reducedFacetsFilters(assetFacets);
    const reducedDatasetFacets: any = reducedFacetsFilters(datasetFacets);
    const reducedResultFacets: any = reducedFacetsFilters(resultFacets);
    const reducedModelFacets: any = reducedFacetsFilters(modelFacets);

    return {
        assetFacets,
        datasetFacets,
        resultFacets,
        modelFacets,
        reducedAssetFacets,
        reducedDatasetFacets,
        reducedResultFacets,
        reducedModelFacets,
    };
}

import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { AccessLevelsOptions } from '@/modules/access-policy/constants/access-levels.constants';
import { computed } from '@vue/composition-api';

export function useAssetFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();

    const commonFilters: Record<string, FacetFilter> = {
        accessLevel: {
            label: 'Access Level',
            placeholder: 'Select access level',
            type: FacetFilterType.Treeselect,
            default: defaultValue(get, 'accessLevel'),
            options: Object.entries(AccessLevelsOptions).map(([key, value]) => ({ id: key, label: value })),
        },
        user: {
            label: 'User',
            placeholder: 'Select user',
            type: FacetFilterType.Treeselect,
            default: defaultValue(get, 'user'),
            options: [],
        },
        relatedPipeline: {
            label: 'Related Pipeline',
            placeholder: 'Enter related pipeline ID',
            tooltip: 'Enter the data check-in/analytics pipeline ID for which you want to find the relevant assets',
            type: FacetFilterType.Text,
            default: { id: defaultValue(get, 'relatedPipeline', false) },
        },
        assetOrigin: {
            label: 'Asset Origin',
            placeholder: 'Enter asset ID',
            tooltip: 'Enter the asset ID whose origin you want to find, i.e. from which assets it derives',
            type: FacetFilterType.Number,
            default: { id: defaultValue(get, 'assetOrigin', false) },
        },
    };

    const assetFilters = computed(
        (): Record<string, FacetFilter> => {
            return {
                ...commonFilters,
                derivativeAssets: {
                    label: 'Derivative Assets',
                    placeholder: 'Enter asset ID',
                    tooltip: 'Enter the asset ID for which you want to find its derivative assets',
                    type: FacetFilterType.Number,
                    default: { id: defaultValue(get, 'derivativeAssets', false) },
                },
            };
        },
    );

    const datasetFilters = computed(
        (): Record<string, FacetFilter> => {
            return {
                temporalCoverage: {
                    label: 'Temporal Coverage',
                    type: FacetFilterType.Date,
                    default: {
                        min: defaultValue(get, 'temporalCoverageMin', false),
                        max: defaultValue(get, 'temporalCoverageMax', false),
                    },
                },
                ...commonFilters,
            };
        },
    );

    const resultFilters = computed(
        (): Record<string, FacetFilter> => {
            return { ...assetFilters.value };
        },
    );

    const modelFilters = computed(
        (): Record<string, FacetFilter> => {
            return { ...assetFilters.value };
        },
    );

    const reducedAssetFilters: any = reducedFacetsFilters(assetFilters);
    const reducedDatasetFilters: any = reducedFacetsFilters(datasetFilters);
    const reducedResultFilters: any = reducedFacetsFilters(resultFilters);
    const reducedModelFilters: any = reducedFacetsFilters(modelFilters);

    return {
        assetFilters,
        datasetFilters,
        resultFilters,
        modelFilters,
        reducedAssetFilters,
        reducedDatasetFilters,
        reducedResultFilters,
        reducedModelFilters,
    };
}
